<template>
  <a-spin :spinning="spinShow">
    <div v-malihu-scrollbar style="overflow-y: auto;" :style="{'max-height': bodyHeight + 'px'}">
      <a-form :form="form" class="form-container-body-form">
        <a-row>
          <a-form-item label="备注名称" v-bind="formItemLayout">
            <a-input v-show="!chooseData.systemModule"
                     v-decorator="['title',
                                    {rules:[
                                      {type:'string',required:true,message:'名称不能为空'}
                                      ],trigger:'change',initialValue:'新建标备注'}
                                    ]"
                     placeholder="请输入名称"></a-input>
            <span v-show="chooseData.systemModule">{{form.getFieldValue('title')}}</span>
          </a-form-item>
        </a-row>
        <a-row>
          <a-form-item label="字体颜色" key="color" v-bind="formItemLayout">
            <a-input
              v-decorator="['color',
                              {rules:[
                                {type:'string',required:true,message:'请输入字体颜色'}
                                ],trigger:'change',initialValue:'#111413'}
                              ]"
              placeholder="请输入字体颜色"></a-input>
          </a-form-item>
        </a-row>
        <a-row>
          <a-form-item label="字体大小(px)" key="fontSize" v-bind="formItemLayout">
            <a-input-number style="width: 100%"
                            v-decorator="['fontSize',
                                          {rules:[
                                            {required:true,message:'请输入字体大小'}
                                            ],trigger:'change',initialValue:14}
                                          ]"
                            placeholder="请输入字体大小"></a-input-number>
          </a-form-item>
        </a-row>
        <a-row>
          <a-form-item label="背景颜色" key="backgroundColor" v-bind="formItemLayout">
            <a-input style="width: 100%"
                     v-decorator="['backgroundColor',
                                                 {rules:[
                                                   {required:true,message:'请输入背景颜色'}
                                                   ],trigger:'change',initialValue:'transparent'}
                                                 ]"
                     placeholder="请输入背景颜色"></a-input>
          </a-form-item>
        </a-row>
        <a-row>
          <a-col span="12">
            <a-form-item label="显示范围" v-bind="{
              labelCol: {
                sm: { span: 12 }
              },
              wrapperCol: {
                sm: { span: 12 }
              }
            }">
              <a-input-number :min="3"
                              :max="18"
                              :step="1"
                              v-decorator="['minZoom',
                                                    {rules:[
                                                      {required:true,message:'请输入最小显示级别'}
                                                      ],trigger:'change',initialValue:11}
                                                    ]"
                              placeholder="请输入最小显示级别"></a-input-number>
            </a-form-item>
          </a-col>
          <a-col span="12">
            <a-form-item v-bind="{
                          labelCol: {
                            sm: { span: 9 }
                          },
                          wrapperCol: {
                            sm: { span: 14 }
                          }
                        }">
              -&nbsp;
              <a-input-number :min="3"
                              :max="18"
                              :step="1"
                              v-decorator="['maxZoom',
                                                {rules:[
                                                  {required:true,message:'请输入最大显示级别'}
                                                  ],trigger:'change',initialValue:18}
                                                ]"
                              placeholder="请输入最大显示级别"></a-input-number>
            </a-form-item>
          </a-col>
        </a-row>
        <!--        <a-row>-->
        <!--          <a-form-item label="背景边框" key="border" v-bind="formItemLayout">-->
        <!--            <a-input style="width: 100%"-->
        <!--                     v-decorator="['border',-->
        <!--                                                 {rules:[-->
        <!--                                                   {required:true,message:'请输入背景边框'}-->
        <!--                                                   ],trigger:'change',initialValue:'1px solid #e1e1e1'}-->
        <!--                                                 ]"-->
        <!--                     placeholder="请输入背景边框"></a-input>-->
        <!--          </a-form-item>-->
        <!--        </a-row>-->
        <a-row>
          <a-form-item label="位置经纬度" v-bind="formItemLayout">
            <a-input
              v-decorator="['position',
                            {rules:[
                              {type:'string',required:true,message:'请点击选择位置经纬度'}
                              ],trigger:'change'}
                            ]"
              placeholder="请点击选择位置经纬度"></a-input>
          </a-form-item>
        </a-row>
      </a-form>
    </div>
    <a-row class="m-b-12 m-r-24 m-t-12">
      <a-button type="primary" class="f-r" @click="submitAdd" :loading="ifSub" v-if="type === 'add'">添加</a-button>
      <a-button type="primary" class="f-r m-l-12" @click="submitEdit" :loading="ifSub" v-if="type === 'edit'">更新</a-button>
      <a-button type="danger" class="f-r" @click="submitDelete" :loading="ifSub" v-if="type === 'edit'">删除</a-button>
    </a-row>
  </a-spin>
</template>

<script>

  import text from '../../api/map/domain/text'
  import { basicForm } from './mixins/common'
  import { mapState } from 'vuex'

  export default {
    name: 'TextForm',
    mixins: [basicForm],
    data () {
      return {
        formItem: text(),
        formType: 'text'
      }
    },
    computed: {
      ...mapState({
        bodyHeight: state => {
          return state.app.bodyHeight - 300
        }
      })
    },
    methods: {
      setPosition (value) {
        this.form.setFieldsValue({
          position: value
        })
      },
      getFormData () {
        this.formItem.position = eval(this.form.getFieldValue('position'))
        this.formItem.text = this.form.getFieldValue('title')
        this.formItem.fontSize = this.form.getFieldValue('fontSize')
        this.formItem.color = this.form.getFieldValue('color')
        this.formItem.backgroundColor = this.form.getFieldValue('backgroundColor')
        // this.formItem.border = this.form.getFieldValue('border')
        this.formItem.minZoom = this.form.getFieldValue('minZoom')
        this.formItem.maxZoom = this.form.getFieldValue('maxZoom')
        this.formItem.zooms = [this.formItem.minZoom, this.formItem.maxZoom]
        this.formItem['draggable'] = false

        return this.formItem
      },
      setDTOFieldsValue (values) {
        return this.getFormData()
      },
      setFormFieldsValue (values, type) {
        const options = JSON.parse(values.options)
        this.$nextTick(() => {
          this.form.setFieldsValue({
            position: values.position,
            title: options.text,
            fontSize: options.fontSize,
            color: options.color,
            backgroundColor: options.backgroundColor,
            // border: options.border,
            minZoom: options.minZoom || 11,
            maxZoom: options.maxZoom || 18
          })
          if (!type) {
            this.$emit('formFieldsSetSuccess')
          }
        })
      }
    }
  }
</script>