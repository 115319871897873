<template>
  <a-button class="icon-btn" shape="circle" @click="selectIcon">
    <a-popover placement="bottom">
      <div slot="title" class="icon-title">
        {{title}}
      </div>
      <template slot="content">
        <div style="width: 100%;display: block">
          <img class="icon-image" :width="60" height="60" :src="bucketUrl+name" :alt="title"/>
        </div>
      </template>
      <img :width="20" height="20" :src="bucketUrl+name" :alt="title"/>
    </a-popover>
  </a-button>
</template>

<script>
  export default {
    name: 'ImageView',
    props: {
      title: {
        type: String,
        default: '图标'
      },
      name: {
        type: String,
        default: '名称'
      },
      bucketUrl: {
        type: String,
        default: ''
      }
    },
    methods: {
      selectIcon () {
        this.$emit('selectIcon', this.name)
      },
    }
  }
</script>

<style scoped>
  .icon-title {
    display: block;
    width: 100%;
    font-size: large;
    line-height: 30px;
    font-weight: bold;
    text-align: center;
  }

  .icon-image {
    margin-left: 40px;
  }

  .icon-btn {
    margin-right: 6px;
  }
</style>