const polyline = () => {
  return {
    title: '',
    bubble: false, // 事件冒泡至地图以外
    cursor: 'pointer',
    draggable: true, //允许拖拽
    zIndex: 50,
    isOutline: false, // 线条是否带描边，默认false
    borderWeight: 1, // 描边的宽度，默认为1
    outlineColor: '#ffeeff', //线条描边颜色，此项仅在isOutline为true时有效，默认：#000000
    path: [], //折线的节点坐标数组
    strokeColor: '#1890ff', //线条颜色，使用16进制颜色代码赋值。默认值为#006600
    strokeOpacity: 0.9, // 线条透明度，取值范围[0,1]，0表示完全透明，1表示不透明。默认为0.9
    strokeWeight: 4, // 线条宽度，单位：像素
    strokeStyle: 'solid', // 线样式，实线:solid，虚线:dashed
    // 勾勒形状轮廓的虚线和间隙的样式，此属性在strokeStyle 为dashed 时有效， 此属性在ie9+浏览器有效 取值：
    // 实线：[0,0,0]
    // 虚线：[10,10] ，[10,10] 表示10个像素的实线和10个像素的空白（如此反复）组成的虚线
    // 点画线：[10,2,10]， [10,2,10] 表示10个像素的实线和2个像素的空白 + 10个像素的实线和10个像素的空白 （如此反复）组成的虚线
    strokeDasharray: [10, 10],
    lineJoin: 'miter', // 折线拐点的绘制样式，默认值为'miter'尖角，其他可选值：'round'圆角、'bevel'斜角
    lineCap: 'butt', // 折线两端线帽的绘制样式，默认值为'butt'无头，其他可选值：'round'圆头、'square'方头
    // 是否延路径显示白色方向箭头,默认false。Canvas绘制时有效，建议折线宽度大于6时使用；
    // 在3D视图下不支持显示方向箭头（自V1.4.0版本参数效果变更）
    showDir: false
  }
}

export default polyline