<template>
  <div class="map-cover" style="width: 100%; height: 100%;">
    <div :id="mapContainerId" class="map-container"></div>
    <!--搜索框开始-->
    <div class="form-search-container">
      <div class="form-container-header">
        <a-input-search class="ant-modal-search" placeholder="请输入搜索关键字" @search="onSearch"></a-input-search>
        <a-dropdown>
          <div class="ant-modal-title">
            <template v-for="item in instanceType">
              <span v-if="item.value === tempInstanceType">{{ item.label }}</span>
            </template>
          </div>
          <a-menu slot="overlay" style="width: 130px;">
            <a-menu-item v-for="item in instanceType" :key="item.value">
              <a target="_blank" @click="handleCheckInstanceType(item)"
              >{{ item.label }} &nbsp;&nbsp;<a-icon
                v-if="item.value === tempInstanceType"
                type="check"
                style="color: #1e88e5; margin-top: 4px;"
                class="f-r"
              />
              </a>
            </a-menu-item>
          </a-menu>
        </a-dropdown>
      </div>
      <a-divider style="margin: 16px 0;"></a-divider>
      <div class="form-search-body">
        <search-device-form
          ref="deviceFormRef"
          v-if="tempInstanceType === 'device_info'"
          @selectItem="selectItem"
          :searchKey="searchKey"
        ></search-device-form>
        <search-device-facility-form
          ref="deviceFacilityFormRef"
          v-if="tempInstanceType === 'monitoring_point'"
          @selectItem="selectItem"
          :searchKey="searchKey"
        ></search-device-facility-form>
        <search-facility-form
          ref="facilityFormRef"
          v-if="tempInstanceType === 'facility_info'"
          @selectItem="selectItem"
          :searchKey="searchKey"
        ></search-facility-form>
        <search-base-form
          ref="baseFormRef"
          v-if="tempInstanceType === 'base'"
          @selectItem="selectItem"
          @addCover="addCover"
          :searchKey="searchKey"
        ></search-base-form>
        <search-depot-form
          ref="depotFormRef"
          v-if="tempInstanceType === 'device_depot'"
          @selectItem="selectItem"
          :searchKey="searchKey"
        ></search-depot-form>
      </div>
    </div>
    <!--搜索框结束-->
    <a-dropdown>
      <div class="drop-container" style="right: 145px;">
        <div class="ant-modal-title drop-container-title">
          <a-spin :spinning="loadLayerData">
            <div class="default-hover cursor-pointer">
              <span v-if="checkLayer === 'none'">图层</span>
              <template v-for="item in layerType">
                <span v-if="item.value === checkLayer">{{ item.label }}</span>
              </template>
            </div>
          </a-spin>
        </div>
      </div>
      <a-menu slot="overlay">
        <a-menu-item v-for="item in layerType" :key="item.value">
          <a target="_blank" @click="handleCheckLayerType(item.value)"
          >{{ item.label }} &nbsp;&nbsp;<a-icon
            v-if="item.value === checkLayer"
            type="check"
            style="color: #1e88e5; margin-top: 4px;"
            class="f-r"
          />
          </a>
        </a-menu-item>
      </a-menu>
    </a-dropdown>
    <a-dropdown>
      <div class="drop-container" style="right: 40px;">
        <div class="ant-modal-title drop-container-title">
          <template v-for="item in mapStyleItems">
            <div class="default-hover cursor-pointer">
              <span v-if="item.value === chooseMapStyle">  {{ item.label }}</span>
            </div>
          </template>
        </div>
      </div>
      <a-menu slot="overlay">
        <a-menu-item v-for="item in mapStyleItems" :key="item.value">
          <a target="_blank" @click="chooseMapStyleHandle(item)">{{ item.label }} &nbsp;&nbsp;<a-icon
            type="check"
            v-if="chooseMapStyle === item.value"
            style="color: #1e88e5; margin-top: 4px;"
            class="f-r"
          />
          </a>
        </a-menu-item>
      </a-menu>
    </a-dropdown>
    <div class="form-container" v-show="formShow">
      <div class="form-container-header">
        <button type="button" class="ant-modal-close" @click="closeForm()">
          <span class="ant-modal-close-x"><a-icon type="close"></a-icon></span>
        </button>
        <div class="ant-modal-title">
          <template v-for="item in coverType">
            <span v-if="item.value === checkCover">{{ item.label }}</span>
          </template>
        </div>
      </div>
      <a-divider style="margin: 16px 0;"></a-divider>
      <div class="form-container-body">
        <marker-form
          ref="markerFormRef"
          v-if="checkCover === 'marker'"
          :chooseData="chooseData"
          @onUploadSuccess="markerIconUploadSuccess"
          @formFieldsSetSuccess="formFieldsSetSuccess"
          @editSuccess="editSuccessHandle"
          @addSuccess="addSuccessHandle"
          @deleteSuccess="deleteSuccessHandle"
          @onValuesChange="onMarkerValuesChange"
        ></marker-form>
        <polyline-form
          ref="polylineFormRef"
          v-if="checkCover === 'polyline'"
          :chooseData="chooseData"
          @editSuccess="editSuccessHandle"
          @addSuccess="addSuccessHandle"
          @deleteSuccess="deleteSuccessHandle"
          @formFieldsSetSuccess="formFieldsSetSuccess"
          @onValuesChange="onPolylineValuesChange"
        ></polyline-form>
        <polygon-form
          ref="polygonFormRef"
          v-if="checkCover === 'polygon'"
          :chooseData="chooseData"
          @editSuccess="editSuccessHandle"
          @addSuccess="addSuccessHandle"
          @deleteSuccess="deleteSuccessHandle"
          @formFieldsSetSuccess="formFieldsSetSuccess"
          @onValuesChange="onPolygonValuesChange"
        ></polygon-form>
        <text-from
          ref="textFormRef"
          v-if="checkCover === 'text'"
          :chooseData="chooseData"
          @formFieldsSetSuccess="formFieldsSetSuccess"
          @editSuccess="editSuccessHandle"
          @addSuccess="addSuccessHandle"
          @deleteSuccess="deleteSuccessHandle"
          @onValuesChange="onTextValuesChange"
        ></text-from>
      </div>
    </div>
  </div>
</template>

<script>
  /* eslint-disable no-undef */

  import layer from './mixins/layer'
  import coverView from './mixins/cover-view'
  import mapInit from './mixins/map-init'
  import UUID from '../../utils/UUID'
  import MarkerForm from './MarkerForm'
  import SearchDeviceForm from './SearchDeviceForm'
  import SearchFacilityForm from './SearchFacilityForm'
  import SearchBaseForm from './SearchBaseForm'
  import SearchDepotForm from './SearchDepotForm'
  import PolylineForm from './PolylineForm'
  import PolygonForm from './PolygonForm'
  import TextFrom from './TextForm'
  import SERVICE_URLS from '../../api/service.url'
  import SearchDeviceFacilityForm from './TreeList'

  export default {
    name: 'Cover',
    mixins: [layer, coverView, mapInit],
    components: {
      SearchDeviceFacilityForm,
      PolygonForm,
      MarkerForm,
      PolylineForm,
      TextFrom,
      SearchDeviceForm,
      SearchFacilityForm,
      SearchBaseForm,
      SearchDepotForm
    },
    data () {
      return {
        mapContainerId: new UUID().id,
        map: null,
        formShow: false,
        searchKey: '',
        instanceType: [
          {
            label: '设备/设施',
            value: 'monitoring_point'
          },
          {
            label: '点线面',
            value: 'base'
          }
        ],
        tempInstanceType: 'monitoring_point',
        coverType: [
          {
            label: '标点',
            value: 'marker'
          },
          {
            label: '标线',
            value: 'polyline'
          },
          {
            label: '标面',
            value: 'polygon'
          },
          {
            label: '标备注',
            value: 'text'
          }
        ],
        checkCover: 'marker',
        marker: null,
        polyline: null,
        textMarker: null,
        polyEditor: null,
        polygon: null,
        chooseData: {},
        instance: {},
        mouseTool: null,
        type: 'edit',
        chooseMapStyle: 'normal',
        mapStyleItems: [
          {
            value: 'normal',
            label: '标准'
          },
          {
            value: 'dark',
            label: '幻影黑'
          },
          {
            value: 'light',
            label: '月光银'
          },
          {
            value: 'whitesmoke',
            label: '远山黛'
          },
          {
            value: 'fresh',
            label: '草色青'
          },
          {
            value: 'grey',
            label: '雅士灰'
          },
          {
            value: '8ea104d16c14b242522f70fbda71927c',
            label: '自定义'
          },
          {
            value: 'graffiti',
            label: '涂鸦'
          },
          {
            value: 'macaron',
            label: '马卡龙'
          },
          {
            value: 'darkblue',
            label: '靛青蓝'
          },
          {
            value: 'wine',
            label: '酱籽'
          },
          {
            value: 'satellite',
            label: '卫星图'
          }
        ]
      }
    },
    created () {
      // this.onSearch()
    },
    destroyed () {
      if (this.map) {
        this.map.destroy()
        if (this.removeOverMarkTips) {
           this.removeOverMarkTips()
        }
        this.map =  null
      }
    },
    methods: {
      init () {
        this.map = new AMap.Map(this.mapContainerId, {
         // mapStyle: 'amap://styles/grey',
          mapStyle: 'amap://styles/normal',
          resizeEnable: true,
          features: ['bg', 'road', 'building', 'point'],
          center: [106.230909, 38.487193],
          zoom: 11
        })
        // this.map.on('click', this.mapClick)
        this.mouseTool = new AMap.MouseTool(this.map)
        this.mouseTool.on('draw', this.mapDraw)
        // 加载所有覆盖物数据
        var me = this;
        this.handleCheckLayerType(this.tempInstanceType)
        this.map.on('moveend', function () {
          if (me.removeOverMarkTips) {
            me.removeOverMarkTips()
          }
        })
      },
      // 关闭form
      closeForm () {
        this.formShow = false
        // this.clearOverlay()
        // this.chooseData = {}
        // this.instance = {}
        // this.checkCover = 'none'
      },
      onSearch (value) {
        this.searchKey = value
        this.closeForm()
        if (this.tempInstanceType === 'monitoring_point') {
          this.getLeftModalRef().onTreeSearch(this.searchKey)
        } else {
          this.getLeftModalRef().onSearch()
        }
      },
      onSearchNotRestPage (action) {
        if (this.tempInstanceType === 'monitoring_point') {
          if (this.$refs['deviceFacilityFormRef'].selectTreeNode) {
            this.getLeftModalRef().onLoadData(this.$refs['deviceFacilityFormRef'].selectTreeNode)
          }
        } else if (this.tempInstanceType === 'base') {
          this.getLeftModalRef().onRefresh()
        }
      },

      getLeftModalRef () {
        let $ref
        if (this.tempInstanceType === 'device_info') {
          $ref = this.$refs['deviceFormRef']
        } else if (this.tempInstanceType === 'facility_info') {
          $ref = this.$refs['facilityFormRef']
        } else if (this.tempInstanceType === 'base') {
          $ref = this.$refs['baseFormRef']
        } else if (this.tempInstanceType === 'device_depot') {
          $ref = this.$refs['depotFormRef']
        } else if (this.tempInstanceType === 'monitoring_point') {
          $ref = this.$refs['deviceFacilityFormRef']
        }
        return $ref
      },

      // 抓取服务端数据
      selectItem (type, id, name, coverType) {
        this.getCurrentFormRef().showTextMemo = false
        console.log(type, id, name, coverType)
        // 先查询是否已存在与页面coverItems中
        const coverObj = this.findCoverByInstanceId(id, type)
        if (coverObj) {
          this.coverClick(coverObj.cover[0])
        } else {
          this.checkCover = coverType
          this.initCover()
          this.$nextTick(() => {
            this.getCurrentFormRef().setLoading(true)
            // this.showTextMemo = false
          })
          this.chooseData = {
            systemModule: type,
            instanceId: id,
            title: name
          }
          let url = SERVICE_URLS.overlay.viewByInstance
          let data = {
            instance: this.chooseData.instanceId,
            module: this.chooseData.systemModule
          }
          let params = {}
          if (this.chooseData.systemModule === 'base') {
            url = SERVICE_URLS.overlay.view
            params['id'] = this.chooseData.instanceId
            data = {}
            this.chooseData['systemModule'] = undefined
          }
          this.formShow = true
          this.$http(this, {
            url: url,
            data: data,
            params: params,
            loading: 'no',
            noTips: true,
            success: (data) => {
              this.getCurrentFormRef().setLoading(false)
              if (data.body) {
                //this.instance = data.body
                //this.formShow = true
                //this.showDataToMap()
                this.addCoverToMapView(data.body)
              } else {
                // this.formShow = false
                this.$nextTick(() => {
                  this.getCurrentFormRef().setTitle(this.chooseData.title)
                  // 进入绘制
                  this.startDrawCover()
                })
              }
            },
            error: () => {
              this.getCurrentFormRef().setLoading(false)
            }
          })
        }
      },

      initCover () {
        this.instance = {}
        this.mouseTool.close(true)
        this.closePolygonEdit()
        this.closePolylineEdit()
        this.closeOverlay()
        this.map.off('click', this.mapClick)
      },

      // 开始绘制 页面上已存在的状态需要关闭，增加对应操作事件
      startDrawCover () {
        const formData = this.getCurrentFormRef().getFormData()
        if (this.checkCover === 'polyline') {
          this.mouseTool.polyline(formData)
        } else if (this.checkCover === 'polygon') {
          this.mouseTool.polygon(formData)
        } else if (this.checkCover === 'marker' || this.checkCover === 'text') {
          this.map.on('click', this.mapClick)
        }
      },
      showDataToMap () {
        if (this.instance.id) {
          this.checkCover = this.instance.type
          this.$nextTick(() => {
            this.getCurrentFormRef().setEditData(this.instance)
          })
        }
      },

      formFieldsSetSuccess () {
        if (this.checkCover === 'marker') {
          this.onMarkerChange('edit')
        } else if (this.checkCover === 'polyline') {
          this.onPolylineChange('edit')
        } else if (this.checkCover === 'polygon') {
          this.onPolygonChange('edit')
        }
      },

      // 获取当前显示的Form引用
      getCurrentFormRef () {
        return this.$refs[this.checkCover + 'FormRef']
      },
      addCover (value) {
        this.chooseData['systemModule'] = undefined
        this.initCover()
        if (value === this.checkCover) {
          this.getCurrentFormRef().resetForm()
        }
        this.checkCover = value
        this.formShow = true
        this.$nextTick(() => {
          this.startDrawCover()
        })
      },
      // 在地图上移除当前选中覆盖物
      clearOverlay () {
        this.closePolygonEdit()
        this.closePolylineEdit()
        if (this.marker) {
          this.marker.setMap(null)
          this.marker = null
        }
        if (this.polyline) {
          this.polyline.setMap(null)
          this.polyline = null
        }
        if (this.polygon) {
          this.polygon.setMap(null)
          this.polygon = null
        }
        if (this.textMarker) {
          this.textMarker.setMap(null)
          this.textMarker = null
        }
      },
      handleCheckInstanceType (item) {
        this.searchKey = ''
        this.tempInstanceType = item.value
        this.closeForm()
        this.$nextTick(() => {
          this.getLeftModalRef().onSearch()
        })
        this.handleCheckLayerType(this.tempInstanceType)
      },
      mapClick (e) {
        console.log('mapClick')
        if (this.checkCover === 'marker' || this.checkCover === 'text') {
          this.$refs[this.checkCover + 'FormRef'].setPosition('[ ' + e.lnglat.getLng() + ',' + e.lnglat.getLat() + ' ]')
          if (this.checkCover === 'text') {
            this.onTextChange()
          } else {
            this.onMarkerChange()
          }
          this.formShow = true
          this.map.off('click', this.mapClick)
        }
      },
      mapDraw (e) {
        const path = []
        e.obj.getPath().forEach((x) => {
          path.push('[ ' + x.lng + ',' + x.lat + ' ]')
        })
        if (this.checkCover === 'polyline') {
          if (this.$refs['polylineFormRef']) {
            this.$refs['polylineFormRef'].setPolylinePosition(path)
            this.onPolylineChange()
          }
        } else if (this.checkCover === 'polygon') {
          if (this.$refs['polygonFormRef']) {
            this.$refs['polygonFormRef'].setPolygonPosition(path)
            this.onPolygonChange()
          }
        }
        this.formShow = true
        this.mouseTool.close(true)
      },
      onMarkerChange (type) {
        // 获取 markerFormRef 中保存的marker信息，并设置在地图
        this.$nextTick(() => {
          if (this.marker) {
            this.marker.setMap(null)
          }
          const markerInfo = this.$refs['markerFormRef'].getFormData()
          markerInfo.draggable = true
          this.marker = new AMap.Marker(markerInfo)
          this.marker.setMap(this.map)
          this.marker.on('dragend', this.markerDragend)
          this.marker.on('click', () => {
            this.formShow = true
          })
          if (type === 'edit') {
            if (!this.map) return
            this.map.setFitView(this.marker)
          }
        })
      },
      onPolylineChange (type) {
        this.$nextTick(() => {
          const polylineInfo = this.$refs['polylineFormRef'].getFormData()
          if (this.polyline) {
            this.polyline.setMap(null)
          }

          this.polyline = new AMap.Polyline(polylineInfo)
          this.polyline.setMap(this.map)
          this.polyline.on('dragend', this.polylineDragend)
          this.polyline.on('click', this.openPolylineEdit)
          this.polyline.on('rightclick', this.closePolylineEdit)

          if (type === 'edit') {
            if (!this.map) return
            this.map.setFitView(this.polyline)
          }
        })
      },
      onPolygonChange (type) {
        this.$nextTick(() => {
          const polygonInfo = this.$refs['polygonFormRef'].getFormData()
          if (this.polygon) {
            this.polygon.setMap(null)
          }

          if (polygonInfo.path.length > 2) {
            // 多边形至少3个点
            this.polygon = new AMap.Polygon(polygonInfo)
            this.polygon.setMap(this.map)
            this.polygon.on('dragend', this.polygonDragend)
            this.polygon.on('click', this.openPolygonEdit)
            this.polygon.on('rightclick', this.closePolygonEdit)
          }

          if (type === 'edit') {
            if (!this.map) return
            this.map.setFitView(this.polyline)
          }
        })
      },
      onTextChange (type) {
        this.$nextTick(() => {
          if (this.textMarker) {
            this.textMarker.setMap(null)
          }
          const textInfo = this.$refs['textFormRef'].getFormData()
          textInfo.draggable = true
          this.textMarker = this.buildTextMarker(textInfo)
          this.textMarker.setMap(this.map)
          this.textMarker.on('dragend', this.textDragend)
          this.textMarker.on('click', () => {
            this.formShow = true
          })
          if (type === 'edit') {
            if (!this.map) return
            this.map.setFitView(this.textMarker)
          }
        })
      },
      openPolygonEdit () {
        this.polygonEdit(true)
      },
      closePolygonEdit () {
        this.polygonEdit(false)
      },
      openPolylineEdit () {
        this.polylineEdit(true)
      },
      closePolylineEdit () {
        this.polylineEdit(false)
      },
      // Form 变化回调
      onMarkerValuesChange (values) {
        const markerInfo = this.$refs['markerFormRef'].getFormData()
        if (markerInfo.position) {
          if (this.marker) {
            // if (values['title'] || values['labelFontColor']
            //   || values['labelFontSize'] || values['labelOffset']
            //   || values['showTextMemo'] === true) {
            //   this.marker.setLabel(markerInfo.label)
            // }
            // if (values['showTextMemo'] === false) {
            //   this.marker.setLabel(null)
            // }
            this.marker.setContent(this.buildMarkerContent(markerInfo, this.instance.id, 'show'))
            if (markerInfo.showTextMemo === true) {
              this.marker.setLabel(markerInfo.label)
            } else {
              this.marker.setLabel(null)
            }
            this.marker.setPosition(markerInfo.position)
            this.map.setCenter(markerInfo.position)
          } else {
            this.onMarkerChange()
          }
        }
      },
      // 标线 Form变化回调
      onPolylineValuesChange (values) {
        // if (values['path'] && this.polyline) {
        //   if (values['path'].length > 1) {
        //     this.polyline.setPath(values['path'])
        //   } else {
        //     this.polyline.setMap(null)
        //     this.polyline = null
        //   }
        // } else if (values['isOutline'] || values['borderWeight']
        //   || values['outlineColor'] || values['strokeColor']
        //   || values['strokeOpacity'] || values['strokeWeight']
        //   || values['strokeStyle'] || values['strokeDasharray']
        //   || values['lineJoin'] || values['lineCap'] || (values['showDir'] === true || values['showDir'] === false)) {
        //   const polylineInfo = this.$refs['polylineFormRef'].getFormData()
        //   if (polylineInfo.path && polylineInfo.path.length > 1 && this.polyline) {
        //     this.polyline.setOptions(polylineInfo)
        //   }
        // }
        const polylineInfo = this.$refs['polylineFormRef'].getFormData()
        console.log(polylineInfo)
        if (polylineInfo.path && polylineInfo.path.length > 1 && this.polyline) {
          this.polyline.setOptions(polylineInfo)
        }
      },
      // 多面体 Form变化回调
      onPolygonValuesChange (values) {
        // if (values['path'] && this.polygon) {
        //   if (values['path'].length > 2) {
        //     this.polygon.setPath(values['path'])
        //   } else {
        //     this.polygon.setMap(null)
        //     this.polygon = null
        //   }
        // } else if (values['strokeColor'] || values['strokeOpacity']
        //   || values['strokeWeight'] || values['strokeStyle']
        //   || values['strokeDasharray'] || values['fillColor'] || values['fillOpacity']) {
        //   const polygonInfo = this.$refs['polygonFormRef'].getFormData()
        //   if (polygonInfo.path && polygonInfo.path.length > 2 && this.polygon) {
        //     this.polygon.setOptions(polygonInfo)
        //   }
        // }
        const polygonInfo = this.$refs['polygonFormRef'].getFormData()
        if (polygonInfo.path && polygonInfo.path.length > 2 && this.polygon) {
          this.polygon.setOptions(polygonInfo)
        }
      },
      onTextValuesChange () {
        if (this.textMarker) {
          const textInfo = this.$refs['textFormRef'].getFormData()
          this.textMarker.setText(textInfo.text)
          this.textMarker.setStyle(this.buildTextMarkerStyle(textInfo))
          this.textMarker.setPosition(textInfo.position)
        }
      },
      polylineEdit (state) {
        if (state) {
          this.formShow = true
        }
        if (this.polyline) {
          const options = this.polyline.getOptions()
          options.draggable = state
          this.polyline.setOptions(options)
        }
        if (state && this.polyline && !this.polyEditor) {
          this.polyEditor = new AMap.PolyEditor(this.map, this.polyline)
          this.polyEditor.on('addnode', this.polylinePathChange)
          this.polyEditor.on('adjust', this.polylinePathChange)
          this.polyEditor.on('removenode', this.polylinePathChange)
          this.polyEditor.open()
        } else if (!state && this.polyEditor) {
          this.polyEditor.close()
          this.polyEditor = null
        }
      },

      polylinePathChange (event) {
        const temp = JSON.parse(this.instance.options)
        const path = []
        this.polyline.getPath().forEach((x) => {
          path.push([x.lng, x.lat])
        })
        temp['path'] = path
        this.instance.options = JSON.stringify(temp)
        this.polylineDragend()
      },

      polygonEdit (state) {
        if (state) {
          this.formShow = true
        }
        if (this.polygon) {
          const options = this.polygon.getOptions()
          options.draggable = state
          this.polygon.setOptions(options)
        }
        if (state && this.polygon && !this.polyEditor) {
          this.polyEditor = new AMap.PolyEditor(this.map, this.polygon)
          this.polyEditor.on('addnode', this.polygonPathChange)
          this.polyEditor.on('adjust', this.polygonPathChange)
          this.polyEditor.on('removenode', this.polygonPathChange)
          this.polyEditor.open()
        } else if (!state && this.polyEditor) {
          this.polyEditor.close()
          this.polyEditor = null
        }
      },

      polygonPathChange (event) {
        const temp = JSON.parse(this.instance.options)
        const path = []
        this.polygon.getPath().forEach((x) => {
          path.push([x.lng, x.lat])
        })
        temp['path'] = path
        this.instance.options = JSON.stringify(temp)
        this.polygonDragend()
      },

      // marker 拖动回调
      markerDragend (e) {
        this.$refs['markerFormRef'].setPosition('[ ' + e.lnglat.getLng() + ',' + e.lnglat.getLat() + ' ]')
      },
      polylineDragend (e) {
        const path = []
        this.polyline.getPath().forEach((x) => {
          path.push('[ ' + x.lng + ',' + x.lat + ' ]')
        })
        if (this.$refs['polylineFormRef']) {
          this.$refs['polylineFormRef'].setPolylinePosition(path)
        }
      },
      polygonDragend (e) {
        const path = []
        this.polygon.getPath().forEach((x) => {
          path.push('[ ' + x.lng + ',' + x.lat + ' ]')
        })
        if (this.$refs['polygonFormRef']) {
          this.$refs['polygonFormRef'].setPolygonPosition(path)
        }
      },
      textDragend (e) {
        this.$refs['textFormRef'].setPosition('[ ' + e.lnglat.getLng() + ',' + e.lnglat.getLat() + ' ]')
      },
      addCoverToMapView (values) {
        const { coverItemsAdd, setCoverToMap, coverClick, map } = this
        const coverObj = coverItemsAdd(values)
        setCoverToMap(coverObj, map)
        coverClick(coverObj.cover[0])
      },
      // 表单数据变化
      addSuccessHandle (values) {
        this.addCoverToMapView(values)
        this.onSearchNotRestPage(this.monitoringPointsAction)
      },
      editSuccessHandle (values) {
        if (this.checkCover === 'polyline') {
          this.onPolylineValuesChange(values)
        } else if (this.checkCover === 'polygon') {
          this.onPolygonValuesChange()
        } else if (this.checkCover === 'marker') {
          this.onMarkerValuesChange()
        } else if (this.checkCover === 'text') {
          this.onTextValuesChange()
        }
        this.instance = values
        // 使用了图层方式
        if (this.checkLayer !== 'none') {
          const cover = this.findCoverByKey(values.id)
          cover.instance = values
        }
        eval('this.on' + this.checkCover.charAt(0).toUpperCase() + this.checkCover.slice(1) + 'ValuesChange()')
        // 基础更新需要设置组件左边组件中的 name
        if (this.tempInstanceType === 'base') {
          this.$refs['baseFormRef'].setNameById(this.instance.name, this.instance.id)
        }
      },
      monitoringPointsAction () {
        let action = null
        if (this.tempInstanceType === 'monitoring_point') {
          action = 'doNothing'
        } else {
          action = null
        }
        return action
      },
      deleteSuccessHandle () {
        this.onSearchNotRestPage(this.monitoringPointsAction)
        this.removeCoverByKey(this.instance.instanceId)
        this.instance = {}
        this.clearOverlay()
        this.formShow = false
      },
      coverClick (target) {
        this.closeOverlay()
        const cover = this.findCoverByKey(target.getExtData()['key'])
        this.instance = cover.instance
        this.chooseData = {
          systemModule: cover.instance.systemModule,
          instanceId: cover.instance.id,
          title: cover.instance.name
        }
        this.checkCover = cover.type
        if (cover.type === 'marker') {
          this.marker = cover.cover[0]
          this.marker.on('dragend', this.markerDragend)
          this.marker.setDraggable(true)
          this.setSelectMarkerAnimation(this.marker)
        } else if (cover.type === 'polyline') {
          this.polyline = cover.cover[0]
          this.openPolylineEdit()
          this.polyline.on('dragend', this.polylineDragend)
          this.polyline.on('click', this.openPolylineEdit)
          this.polyline.on('rightclick', this.closePolylineEdit)
        } else if (cover.type === 'polygon') {
          this.polygon = cover.cover[0]
          this.openPolygonEdit()
          this.polygon.on('dragend', this.polygonDragend)
          this.polygon.on('click', this.openPolygonEdit)
          this.polygon.on('rightclick', this.closePolygonEdit)
        } else if (cover.type === 'text') {
          this.textMarker = cover.cover[0]
          this.textMarker.on('dragend', this.textDragend)
          this.textMarker.setDraggable(true)
        }
        this.formShow = true
        this.$nextTick(() => {
          this.getCurrentFormRef().setEditData(this.instance, 'noCallback')
        })
        if (cover.type === 'text' || cover.type === 'marker') {
          this.map.setCenter(cover.cover[0].getPosition())
        } else {
          if (!this.map) return
          const zoom = this.map.getZoom()
          this.map.setFitView(cover.cover[0])
          this.map.setZoom(zoom)
        }
      },
      closeOverlay () {
        if (this.marker) {
          this.marker.off('dragend', this.markerDragend)
          this.marker.setDraggable(false)
          this.removeSelectMarkerAnimation(this.marker)
          if (this.marker.getExtData() && !this.marker.getExtData()['key']) {
            this.marker.setMap(null)
          }
          this.marker = null
        }
        if (this.polyline) {
          this.closePolylineEdit()
          this.polyline.off('dragend', this.polylineDragend)
          this.polyline.off('click', this.openPolylineEdit)
          this.polyline.off('rightclick', this.closePolylineEdit)
          if (this.polyline.getExtData() && !this.polyline.getExtData()['key']) {
            this.polyline.setMap(null)
          }
          this.polyline = null
        }
        if (this.polygon) {
          this.closePolygonEdit()
          this.polygon.off('dragend', this.polygonDragend)
          this.polygon.off('click', this.openPolygonEdit)
          this.polygon.off('rightclick', this.closePolygonEdit)
          if (this.polygon.getExtData() && !this.polygon.getExtData()['key']) {
            this.polygon.setMap(null)
          }
          this.polygon = null
        }
        if (this.textMarker) {
          if (this.textMarker.getExtData() && !this.textMarker.getExtData()['key']) {
            this.textMarker.setMap(null)
          }
          this.textMarker = null
        }
        this.mouseTool.close()
      },
      // 图片上传成功
      markerIconUploadSuccess () {
        if (this.checkCover === 'marker' && this.marker) {
          const markerInfo = this.$refs['markerFormRef'].getFormData()
          this.marker.setContent(this.buildMarkerContent(markerInfo, this.instance.id))
          this.setSelectMarkerAnimation(this.marker)
        }
      },
      chooseMapStyleHandle (item) {
        this.chooseMapStyle = item.value
        if (this.map) {
          if (this.chooseMapStyle === 'satellite') {
            this.map.add([this.getSatelliteLayer()])
          } else {
            this.map.remove([this.getSatelliteLayer()])
            this.map.setMapStyle('amap://styles/' + item.value)
          }
        }
      },
      getSatelliteLayer () {
        if (!this.satelliteLayer) {
          this.satelliteLayer = new AMap.TileLayer.Satellite()
        }
        return this.satelliteLayer
      }
    }
  }
</script>
