<template>
  <a-spin :spinning="spinShow">
    <div v-malihu-scrollbar style="overflow-y: auto;" :style="{'max-height': bodyHeight + 'px'}">
      <a-form :form="form" class="form-container-body-form">
        <a-row>
          <a-form-item label="名称" v-bind="formItemLayout">
            <a-input v-show="!chooseData.systemModule"
                     v-decorator="['title',
                                                  {rules:[
                                                    {type:'string',required:true,message:'名称不能为空'}
                                                    ],trigger:'change'}
                                                  ]"
                     placeholder="请输入名称"></a-input>
            <span v-show="chooseData.systemModule">{{form.getFieldValue('title')}}</span>
          </a-form-item>
        </a-row>
        <a-row>
          <a-form-item label="线条粗细(px)" v-bind="formItemLayout">
            <a-input-number style="width: 100%"
                            v-decorator="['strokeWeight',
                                                      {rules:[
                                                        {required:true,message:'请输入线条粗细(px)'}
                                                        ],trigger:'change',initialValue:4}
                                                      ]"
                            placeholder="请输入线条粗细(px)"></a-input-number>
          </a-form-item>
        </a-row>
        <a-row>
          <a-form-item label="线条颜色" v-bind="formItemLayout">
            <!--<a-input
              v-decorator="['strokeColor',
                                          {rules:[
                                            {type:'string',required:true,message:'请输入线条颜色'}
                                            ],trigger:'change',initialValue:'#1890ff'}
                                          ]"
              placeholder="请输入线条颜色"></a-input>-->
            <chrome-color-picker
              v-decorator="['strokeColor',
                                     {rules:[
                                       {type:'string',required:true,message:'请选择线条颜色'}
                                       ],trigger:'change',initialValue:'#1890ff'}
                                     ]"
            ></chrome-color-picker>
          </a-form-item>
        </a-row>
        <a-row>
          <a-form-item label="线条透明度" v-bind="formItemLayout">
            <a-input-number style="width: 100%"
                            :min="0"
                            :max="1"
                            :step="0.1"
                            v-decorator="['strokeOpacity',
                                                            {rules:[
                                                              {required:true,message:'线条透明度(0-1)'}
                                                              ],trigger:'change',initialValue:0.9}
                                                            ]"
                            placeholder="请输入线条透明度(0-1)"></a-input-number>
          </a-form-item>
        </a-row>
        <a-row>
          <a-form-item label="线条样式" v-bind="formItemLayout" required>
            <a-radio-group v-decorator="['strokeStyle',{initialValue:'solid'}]">
              <a-radio :value="'solid'">实线</a-radio>
              <a-radio :value="'dashed'">虚线</a-radio>
            </a-radio-group>
          </a-form-item>
        </a-row>
        <a-row v-if="form.getFieldValue('strokeStyle') === 'dashed'" key="strokeDasharray">
          <a-form-item label="虚线间隙样式" v-bind="formItemLayout">
            <a-input
              v-decorator="['strokeDasharray',
                                                 {rules:[
                                                   {type:'string',required:true,message:'请输入虚线间隙样式'}
                                                   ],trigger:'change',initialValue:'[10, 10]'}
                                                 ]"
              placeholder="请输入虚线间隙样式"></a-input>
          </a-form-item>
        </a-row>
        <a-row>
          <a-form-item label="折线拐点样式" v-bind="formItemLayout" required>
            <a-radio-group v-decorator="['lineJoin',{initialValue:'miter'}]">
              <a-radio :value="'miter'">尖角</a-radio>
              <a-radio :value="'round'">圆角</a-radio>
              <a-radio :value="'bevel'">斜角</a-radio>
            </a-radio-group>
          </a-form-item>
        </a-row>
        <a-row>
          <a-form-item label="折线两端样式" v-bind="formItemLayout" required>
            <a-radio-group v-decorator="['lineCap',{initialValue:'butt'}]">
              <a-radio :value="'butt'">无头</a-radio>
              <a-radio :value="'round'">圆头</a-radio>
              <a-radio :value="'square'">方头</a-radio>
            </a-radio-group>
          </a-form-item>
        </a-row>
        <a-row>
          <a-form-item label="方向显示" v-bind="formItemLayout" required>
            <a-radio-group v-decorator="['showDir',{initialValue:true}]">
              <a-radio :value="true">显示</a-radio>
              <a-radio :value="false">不显示</a-radio>
            </a-radio-group>
          </a-form-item>
        </a-row>
        <a-row>
          <a-form-item label="线条描边" v-bind="formItemLayout" required>
            <a-radio-group v-decorator="['isOutline',{initialValue:true}]">
              <a-radio :value="true">显示</a-radio>
              <a-radio :value="false">不显示</a-radio>
            </a-radio-group>
          </a-form-item>
        </a-row>
        <template v-if="form.getFieldValue('isOutline')">
          <a-row>
            <a-form-item label="描边宽度(px)" v-bind="formItemLayout">
              <a-input-number style="width: 100%"
                              v-decorator="['borderWeight',
                                                                        {rules:[
                                                                          {required:true,message:'请输入描边宽度(px)'}
                                                                          ],trigger:'change',initialValue:1}
                                                                        ]"
                              placeholder="请输入描边宽度(px)"></a-input-number>
            </a-form-item>
          </a-row>
          <a-row>
            <a-form-item label="描边颜色" v-bind="formItemLayout">
              <!--<a-input
                v-decorator="['outlineColor',
                                                            {rules:[
                                                              {type:'string',required:true,message:'请输入描边颜色'}
                                                              ],trigger:'change',initialValue:'#ffeeff'}
                                                            ]"
                placeholder="请输入描边颜色"></a-input>-->
              <chrome-color-picker
                v-decorator="['outlineColor',
                                       {rules:[
                                         {type:'string',required:true,message:'请选择描边颜色'}
                                         ],trigger:'change',initialValue:'#ffeeff'}
                                       ]"
              ></chrome-color-picker>
            </a-form-item>
          </a-row>
        </template>
        <a-row>
          <a-form-item label="坐标转换" v-bind="formItemLayout">
            <a-input  class="item-panel" style="width: 260px"
                      v-decorator="[`planeCoordinates`]" placeholder="平面坐标"></a-input>
            <a-icon
              class="dynamic-delete-button m-l-12"
              style="font-size: 18px"
              type="api"
              @click="changPlaneCood"
            />
          </a-form-item>
        </a-row>
        <a-row>
          <a-form-item label="经纬度" v-bind="formItemLayout">
            <draggable
              v-model="positionItems"
              :dragid="'drag-container'"
              v-bind="dragOption"
              @change="dragChange($event, positionItems)"
            >
<!--              <div class="position" :key="'postion_'+k" v-for="(k, index) in form.getFieldValue('keys')">-->
              <div class="position" :key="k" v-for="(k, index) in form.getFieldValue('keys')">
                <a-input  class="item-panel" style="width: 260px"
                         v-decorator="[`position[${k}]`,
                                                      {rules:[
                                                        {type:'string',required:true,message:'请选择经纬度'}
                                                        ],trigger:'change',initialValue:setPositionValue(index)}
                                                      ]"
                         placeholder="请选择经纬度"></a-input>
                <a-icon
                  class="dynamic-delete-button m-l-12"
                  style="font-size: 18px"
                  type="minus-circle-o"
                  @click="() => removePosition(index,k)"
                />
              </div>
            </draggable>
          </a-form-item>
        </a-row>
      </a-form>
    </div>
    <a-row class="m-b-12 m-r-24 m-t-12">
      <a-button type="primary" class="f-r m-l-12" @click="submitAdd" :loading="ifSub"
                v-if="type === 'add'">添加
      </a-button>
      <a-button type="primary" class="f-r m-l-12" @click="submitEdit" :loading="ifSub"
                v-if="type === 'edit'">更新
      </a-button>
      <a-button type="danger" class="f-r m-l-12" @click="submitDelete" :loading="ifSub"
                v-if="type === 'edit'">删除
      </a-button>
    </a-row>
  </a-spin>
</template>

<script>
  import polyline from '../../api/map/domain/polyline'
  import { basicForm } from './mixins/common'
  import { mapState } from 'vuex'
  import Draggable from 'vuedraggable'
  import { ChromeColorPicker } from '../../components/color-picker'
  import SERVICE_URLS from '@/api/service.url'

  let id = 0

  export default {
    name: 'PolylineForm',
    mixins: [basicForm],
    components: { Draggable, ChromeColorPicker },
    data () {
      return {
        formItem: polyline(),
        dragOption: {
          group: 'position',
          ghostClass: 'sortable-ghost',
          chosenClass: 'chosenClass'
        },
        positionItems: [],
        formType: 'polyline',
      }
    },
    computed: {
      ...mapState({
        bodyHeight: state => {
          return state.app.bodyHeight - 300
        }
      })
    },
    created () {
      this.form.getFieldDecorator('keys', { initialValue: [], preserve: true })
      this.form.getFieldDecorator('isOutline', { initialValue: true, preserve: true })
      this.form.getFieldDecorator('showDir', { initialValue: true, preserve: true })
      this.form.getFieldDecorator('lineCap', { initialValue: 'butt', preserve: true })
      this.form.getFieldDecorator('lineJoin', { initialValue: 'miter', preserve: true })
    },
    methods: {
      addPosition (value) {
        const { form } = this
        const keys = form.getFieldValue('keys')
        const nextKeys = keys.concat(++id)
        // can use data-binding to set
        // important! notify form to detect changes
        form.setFieldsValue({
          keys: nextKeys
        })
        this.positionItems.push(value)
      },
      setPolylinePosition (value) {
        console.log(value)
        if (this.positionItems.length === value.length) {
          this.positionItems = value
        } else { // 发生了数据变化
          this.form.setFieldsValue({
            keys: []
          })
          this.positionItems = []
          value.forEach(x => {
            this.addPosition(x)
          })
        }
      },
      removePosition (index, k, type) {
        const { form } = this
        // can use data-binding to get
        const keys = form.getFieldValue('keys')
        // can use data-binding to set
        this.positionItems.splice(index, 1)
        form.setFieldsValue({
          keys: keys.filter(key => key !== k)
        })
        if (!type) {
          this.$emit('onValuesChange', { path: this.parsePath(this.positionItems) })
        }
      },
      getFormData () {

        this.formItem.title = this.form.getFieldValue('title')
        this.formItem.isOutline = this.form.getFieldValue('isOutline')
        if (this.formItem.isOutline) {
          this.formItem.borderWeight = this.form.getFieldValue('borderWeight')
          this.formItem.outlineColor = this.form.getFieldValue('outlineColor')
        }
        this.formItem.strokeColor = this.form.getFieldValue('strokeColor')
        this.formItem.strokeOpacity = this.form.getFieldValue('strokeOpacity')
        this.formItem.strokeWeight = this.form.getFieldValue('strokeWeight')
        this.formItem.strokeStyle = this.form.getFieldValue('strokeStyle')

        if (this.formItem.strokeStyle === 'dashed') {
          const temp = this.form.getFieldValue('strokeDasharray')
          this.formItem.strokeDasharray = eval(temp)
        }

        this.formItem.lineJoin = this.form.getFieldValue('lineJoin')
        this.formItem.lineCap = this.form.getFieldValue('lineCap')
        this.formItem.showDir = this.form.getFieldValue('showDir')
        this.formItem.path = []
        this.positionItems.forEach(x => {
          this.formItem.path.push(eval(x))
        })

        this.formItem['draggable'] = false

        return this.formItem
      },
      // 拖动
      dragChange ($event, positionItems) {
        this.$emit('onValuesChange', { path: this.parsePath(this.positionItems) })
      },
      parsePath () {
        const path = []
        this.positionItems.forEach(x => {
          path.push(eval(x))
        })
        return path
      },
      setPositionValue (index) {
        return this.positionItems[index]
      },
      setDTOFieldsValue (values) {
        return this.getFormData()
      },
      setFormFieldsValue (values, type) {
        this.formItem = polyline()
        this.setPositionType = 'polyline'
        const options = JSON.parse(values.options)
        this.showTextMemo = options.showTextMemo
        this.form.resetFields()
        this.positionItems = []
        this.$nextTick(() => {

          // 设置经纬度
          options.path.forEach(x => {
            this.addPosition(JSON.stringify(x))
          })

          this.form.setFieldsValue({
            title: values.name,
            isOutline: options.isOutline,
            strokeColor: options.strokeColor,
            strokeOpacity: options.strokeOpacity,
            strokeWeight: options.strokeWeight,
            strokeStyle: options.strokeStyle,
            lineJoin: options.lineJoin,
            lineCap: options.lineCap,
            showDir: options.showDir
          })

          if (options.isOutline) {
            this.$nextTick(() => {
              this.form.setFieldsValue({
                borderWeight: options.borderWeight,
                outlineColor: options.outlineColor
              })
            })
          }

          if (options.strokeStyle === 'dashed') {
            this.$nextTick(() => {
              this.form.setFieldsValue({
                strokeDasharray: JSON.stringify(options.strokeDasharray)
              })
            })
          }

          if (!type) {
            this.$emit('formFieldsSetSuccess')
          }
        })
      },
      changPlaneCood() {
        console.log('this.form.getFieldValue:' + this.form.getFieldValue('planeCoordinates'))
        var planCoord = this.form.getFieldValue('planeCoordinates').replace(/\s*/g, "").toLowerCase()
        var planeCoordinate = '';
        var plcs = planCoord.split('x');
        plcs.forEach((xy) => {
          var pp = xy.split('y');
          if (pp.length > 1) {
            planeCoordinate = planeCoordinate + pp[0] + "," + pp[1] + ";"
          }
        })
        console.log("===========================")
        console.log(planeCoordinate)
//this.form.getFieldValue('planeCoordinates')
             this.$http(this, {
          url: SERVICE_URLS.equipment2.equipmentInfoApi.planeCoordinateConvertor,
          data: { planeCoordinate: planeCoordinate },
          noTips: true,
          success: (data) => {
            console.log('111111111111111111111111111111')
            console.log(data)
            if (data.code === 0) {
              var xys = data.body;
              let  resList =  xys.split(';')
              var pathss = []
              resList.forEach((xy) => {
                let sss = xy.split(',')
                if (sss.length > 1) pathss.push('[' + xy + ']')
               })
              this.setPolylinePosition(pathss)
            }
          }
        })
      }
    }
  }
</script>