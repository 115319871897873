<template>
  <a-spin :spinning="spinShow">
    <div v-malihu-scrollbar style="overflow-y: auto;" :style="{'max-height': bodyHeight + 'px'}">
      <div style="padding:20px 20px 20px  20px;">
        <a-list :bordered="false" :dataSource="searchData">
          <a-list-item slot="renderItem"
                       @click.native="clickItem(item)"
                       class="cursor-pointer default-hover" slot-scope="item">
            <a-col :span="19">
              {{item.name}}
            </a-col>
            <a-col :span="5">
              <a-tag :color="item.hasMapOverlay?'blue':'red'" class="f-r">{{item.hasMapOverlay?'已标注':'未标注'}}</a-tag>
            </a-col>
          </a-list-item>
          <div slot="footer">
            <a-pagination size="small"
                          :current="pagination.current"
                          :pageSize="pagination.pageSize"
                          :total="pagination.total"
                          :showTotal="pagination.showTotal"
                          @change="onPageChange"/>
          </div>
        </a-list>
      </div>
    </div>
  </a-spin>
</template>

<script>

  import SERVICE_URLS from '@/api/service.url'
  import { mapState } from 'vuex'

  export default {
    name: 'SearchDepotForm',
    props: {
      searchKey: {
        type: String,
        default: ''
      }
    },
    data () {
      return {
        pagination: {
          current: 1,
          total: 1,
          pageSize: 10,
          showSizeChanger: true,
          showTotal: (total, range) => `第 ${range[0]}-${range[1]} 条 共${total}记录`
        },
        searchParams: {
          number: 0,
          size: 10,
          direction: '',
          order: '',
          name: ''
        },
        searchData: [],
        spinShow: false,
        systemModule: 'device_depot'
      }
    },
    computed: {
      ...mapState({
        bodyHeight: state => {
          return state.app.bodyHeight - 200
        }
      })
    },
    watch: {
      searchKey (val) {
        this.searchParams.name = val
      }
    },
    methods: {
      clickItem (item) {
        this.$emit('selectItem', this.systemModule, item.id, item.name, 'marker')
      },
      /*分页改变*/
      onPageChange (page, pageSize) {
        this.searchParams.number = page - 1
        this.searchParams.size = pageSize
        this.onRefresh()
      },
      /*查询方法*/
      onSearch () {
        this.onRefresh('rest')
      },
      onRefresh (restPage) {
        if (restPage) {
          this.searchParams.number = 0
        }
        this.$http(this, {
          url: SERVICE_URLS.equipment2.depotApi.search,
          data: this.searchParams,
          noTips: true,
          loading: 'spinShow',
          success: (data) => {
            this.searchData = data.body.content
            this.pagination.total = data.body.totalElements
            this.pagination.current = this.searchParams.number + 1
          },
          error: () => {
          }
        })
      }
    }
  }
</script>