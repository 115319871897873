<template>
  <a-spin :spinning="spinning">
    <div class="page-header-index-wide" style="overflow-x: scroll; min-height: 290px" :style="{'max-height': bodyHeight + 'px'}">
      <a-tree :key="menuKey" v-if="treeShow === true && treeData.length>0" show-line :showIcon="true" :load-data="onLoadData" :treeData="treeData" :defaultExpandedKeys="[1]"
              @select="selectEquipmentTypeTree"
              :defaultSelectedKeys="['1-0']">
        <a-icon slot="switcherIcon" type="caret-down"/>
        <template slot="custom" slot-scope="item">
          <a-row>
            <span v-if="item.type !== 'OTHER'">
              <a-icon type="environment" v-if="item.plotType==='marker'" style="margin-right: 10px;"/>
            <a-icon type="dash" v-if="item.plotType==='polyline'" style="margin-right: 10px;"/>
            <a-icon type="radius-setting" v-if="item.plotType==='polygon'" style="margin-right: 10px;"/>
            </span>
            <span v-if="item.type === 'OTHER'">{{item.title}}</span>
            <span v-else :style="{color: item.hasMapOverlay ? 'blue' : ''}">{{item.title}}</span>
            <span style="color: #00A0E9">{{item.num ? '(' + item.num + ')' : ''}}</span>
          </a-row>
        </template>
      </a-tree>
      <a-tree v-if=" treeShow === false && searchTreeData.length>0" show-line :showIcon="true" :defaultExpandAll="true" :treeData="searchTreeData"
              @select="selectEquipmentTypeTree">
        <a-icon slot="switcherIcon" type="caret-down"/>
        <template slot="custom" slot-scope="item">
          <a-row>
            <span v-if="item.type !== 'OTHER'">
              <a-icon type="environment" v-if="item.plotType==='marker'" style="margin-right: 10px;"/>
            <a-icon type="dash" v-if="item.plotType==='polyline'" style="margin-right: 10px;"/>
            <a-icon type="radius-setting" v-if="item.plotType==='polygon'" style="margin-right: 10px;"/>
            </span>
            <span v-if="item.type === 'OTHER'">{{item.title}}</span>
            <span v-else :style="{color: item.hasMapOverlay ? 'blue' : ''}">{{item.title}}</span>
            <span style="color: #00A0E9">{{item.num ? '(' + item.num + ')' : ''}}</span>
          </a-row>
        </template>
      </a-tree>
      <a-empty v-if="treeData.length === 0 && treeShow === true" style="height: 205px;margin-top: 81px;"/>
      <a-empty v-if="searchTreeData.length === 0 && treeShow === false " style="height: 205px;margin-top: 81px;"/>
    </div>
  </a-spin>
</template>
<script>
  import SERVICE_URLS from '@/api/service.url'
  import { mapState } from 'vuex'

  export default {
    name: 'SearchDeviceFacilityForm',
    mixins: [],
    props: {
      type: {
        type: String
      },
      searchKey: {
        type: String,
        default: 'device'
      }
    },
    data () {
      return {
        selectEquipmentTypeKey: '0-0',
        entityBaseUrl: SERVICE_URLS.equipment2.monitoringPoint,
        treeData: [],
        initColumns: [],
        spinning: true,
        selectNode: null,
        parentTreeNode: null,
        parentKey: null,
        systemModule: 'monitoring_point',
        selectTreeNode: null,
        searchData: {
          treeNodeId: 0,
          needMapOverlay: true,
          needNum: true,
          levelNum: 2
        },
        treeShow: true,
        searchTreeData: [],
        menuKey: 1
      }
    },
    computed: {
      ...mapState({
        bodyHeight: state => {
          return state.app.bodyHeight - 200
        }
      })
    },
    watch: {},
    created () {
      this.onSearch()
    },
    methods: {
      onTreeSearch (value) {
        if (value.length !== 0) {
          this.treeShow = false
          let params = {
            name: value,
            treeNodeId: 0,
            needNum: true
          }
          this.$nextTick(() => {
            this.$http(this, {
              url: SERVICE_URLS.equipment2.monitoringPoint.search_tree,
              noLoadingBar: true,
              noTips: true,
              data: params,
              success: (data) => {
                this.searchTreeData = data.body
              }
            })
          })
        } else {
          this.treeShow = true
          ++this.menuKey
        }
      },
      onLoadData (treeNode) {
        this.searchData.treeNodeId = treeNode.$vnode.key
        this.searchData.levelNum = 1
        return new Promise(resolve => {
          if (treeNode.dataRef.key !== this.treeData[0].key) {
            this.$http(this, {
              url: SERVICE_URLS.equipment2.monitoringPoint.build_tree,
              noLoadingBar: true,
              noTips: true,
              data: this.searchData,
              success: (data) => {
                treeNode.dataRef.children = data.body
                this.treeData = [...this.treeData]
              }
            })
          }
          resolve()
        })
      },
      onSearch () {
        this.spinning = true
        this.$http(this, {
          url: SERVICE_URLS.equipment2.monitoringPoint.build_tree,
          noTips: true,
          data: this.searchData,
          success: (data) => {
            // 在组件移除后，重新渲染组件
            // this.$nextTick可实现在DOM 状态更新后，执行传入的方法。
            this.treeData = []
            this.treeData = data.body
            this.spinning = false
          }
        })
      },
      selectEquipmentTypeTree (selectedKeys, e) {
        this.selectTreeNode = e.node.vcTreeNode
        // this.onLoadData(e.node.vcTreeNode)
        this.$emit('selectItem', this.systemModule, e.node.dataRef.key, e.node.dataRef.title, e.node.dataRef.plotType)
      }
    }
  }
</script>
<style scoped>
  .ant-tree-title {
    width: 100%;
  }

  .title {
    float: left;
  }
</style>