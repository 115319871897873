<template>
  <a-popover trigger="click" :overlayClassName="'color-picker'">
    <div slot="content">
      <chrome :value="currentValue" disableAlpha @input="updateValue"></chrome>
      <template v-if="showDefaultColor">
        <a-divider style="margin: 0 0 4px 0"></a-divider>
        <div class="color-picker-colors-wrapper">
          <div class="color-picker-colors-wrapper-color"
               @click="chooseColorHandle(color)"
               v-for="color in defaultColors"
               :style="{'background-color': color}">
            <div class="color-picker-colors-wrapper-circle"
                 :class="currentValue && (currentValue.toLowerCase() === color.toLowerCase())?'':'hide'"></div>
          </div>
          <div class="color-picker-colors-wrapper-color"
               @click="chooseColorHandle('transparent')"
               style='background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAMElEQVQ4T2N89uzZfwY8QFJSEp80A+OoAcMiDP7//483HTx//hx/Ohg1gIFx6IcBALl+VXknOCvFAAAAAElFTkSuQmCC");'>
            <div class="color-picker-colors-wrapper-circle"
                 :class="currentValue && (currentValue.toLowerCase() === 'transparent'.toLowerCase())?'':'hide'"></div>
          </div>
        </div>
      </template>
    </div>
    <template slot="title"></template>
    <div class="color-picker-input ant-input"
         @mouseout="showClose = true"
         @mouseleave="showClose = false">
      <div class="color-picker-container">
        <div class="color-picker-color-empty center" v-if="!currentValue">
          <a-icon type="close"/>
        </div>
        <div class="color-picker-color" v-if="currentValue" :style="colorBgStyle"></div>
      </div>
      <a-icon type="down" class="dropdown-icon" v-if="!allowClear || (!showClose || !currentValue)"/>
      <span class="ant-select-selection__clear"
            style="opacity: 1"
            @click.stop="clearHandle"
            v-if="allowClear && (showClose && currentValue)">
        <a-icon type="close-circle" theme="filled" class="ant-select-clear-icon"/>
      </span>
    </div>
  </a-popover>
</template>

<script>

  import Chrome from 'vue-color/src/components/Chrome'
  import { throttle } from '../../utils/utils'

  export default {
    name: 'ChromeColorPicker',
    props: {
      value: {},
      valueType: {
        type: String,
        default: 'hex'
      },
      allowClear: {
        type: Boolean,
        default: true
      },
      showDefaultColor: {
        type: Boolean,
        default: true
      }
    },
    components: { Chrome },
    computed: {
      colorBgStyle () {
        if (this.currentValue === 'transparent') {
          return {
            'background-image': 'url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAIAAADZF8uwAAAAGUlEQVQYV2M4gwH+YwCGIasIUwhT25BVBADtzYNYrHvv4gAAAABJRU5ErkJggg==)'
          }
        } else {
          return {
            'background-color': this.currentValue
          }
        }
      }
    },
    data () {
      return {
        currentValue: this.value,
        showClose: false,
        defaultColors: ['#2D8CF0', '#19BE6B', '#FF9900', '#ED4014',
          '#00B5FF', '#19C919', '#F9E31C', '#EA1A1A',
          '#9B1DEA', '#00C2B1', '#AC7A33', '#1D35EA',
          '#8BC34A', '#F16B62', '#EA4CA3', '#0D94AA',
          '#FEBD79', '#5D4037', '#00BCD4', '#F06292',
          '#CDDC39', '#000000', '#1C2438',
          '#495060', '#999999', '#FFFFFF']
      }
    },
    watch: {
      value (val) {
        this.currentValue = val
      }
    },
    methods: {
      updateValue (val) {
        // 目前只使用 hex 方式
        if (this.valueType === 'hex') {
          throttle(this.triggerChange, null, 100, val.hex, 200)
        }
      },
      chooseColorHandle (color) {
        this.triggerChange(color)
      },
      triggerChange (changedValue) {
        this.currentValue = changedValue
        // Should provide an event to pass value to Form.
        this.$emit('change', changedValue)
        this.$emit('input', changedValue)
      },
      clearHandle () {
        this.triggerChange('')
      }
    }
  }
</script>