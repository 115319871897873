<template>
  <a-spin :spinning="spinShow">
    <div v-malihu-scrollbar style="overflow-y: auto;" :style="{'max-height': bodyHeight + 'px'}">
      <a-row type="flex" justify="space-around" align="middle">
        <a-col :span="11">
          <a-select showSearch allowClear style="width: 100%"
                    @change="typeChange"
                    placeholder="覆盖物类型">
            <a-select-option
              v-for="(item, index) in typeList"
              :key="index"
              :value="item.value"
              :label="item.label">
              {{ item.label }}
            </a-select-option>
          </a-select>
        </a-col>
        <a-col :span="11">
          <a-select showSearch allowClear style="width: 100%"
                    @change="statusChange"
                    placeholder="状态">
            <a-select-option
              v-for="(item, index) in statusTypeList"
              :key="index"
              :value="item.value"
              :label="item.label">
              {{ item.label }}
            </a-select-option>
          </a-select>
        </a-col>
      </a-row>
      <div style="padding: 20px;">
        <a-list :bordered="false" :dataSource="searchData">
          <a-list-item slot="renderItem" @click.native="clickItem(item)" class="cursor-pointer default-hover"
                       slot-scope="item">
            <a-col :span="19">
              <a-icon type="environment" v-if="item.plotType==='marker'" style="margin-right: 10px;"/>
              <a-icon type="dash" v-if="item.plotType==='polyline'" style="margin-right: 10px;"/>
              <a-icon type="radius-setting" v-if="item.plotType==='polygon'" style="margin-right: 10px;"/>
              {{item.name}}
            </a-col>
            <a-col :span="5">
              <a-tag :color="item.hasMapOverlay?'blue':'red'" class="f-r">{{item.hasMapOverlay?'已标注':'未标注'}}</a-tag>
            </a-col>
          </a-list-item>
          <div slot="footer">
            <a-pagination size="small"
                          :current="pagination.current"
                          :pageSize="pagination.pageSize"
                          :total="pagination.total"
                          :showTotal="pagination.showTotal"
                          @change="onPageChange"/>
          </div>
        </a-list>
      </div>
    </div>
  </a-spin>
</template>

<script>
  import SERVICE_URLS from '@/api/service.url'
  import { mapState } from 'vuex'

  export default {
    name: 'SearchFacilityForm',
    props: {
      searchKey: {
        type: String,
        default: 'device'
      }
    },
    data () {
      return {
        pagination: {
          current: 1,
          total: 1,
          pageSize: 10,
          showSizeChanger: true,
          showTotal: (total, range) => `第 ${range[0]}-${range[1]} 条 共${total}记录`
        },
        searchParams: {
          number: 0,
          size: 10,
          direction: '',
          order: '',
          name: '',
          type: '',
          status: ''
        },
        searchData: [],
        typeList: [
          { value: 'marker', label: '点' },
          { value: 'polyline', label: '线' },
          { value: 'polygon', label: '面' }
        ],
        statusTypeList: [
          { value: 'yes', label: '已标注' },
          { value: 'no', label: '未标注' }
        ],
        systemModule: 'facility_info',
        spinShow: false
      }
    },
    watch: {
      searchKey (val) {
        this.searchParams.name = val
      }
    },
    computed: {
      ...mapState({
        bodyHeight: state => {
          return state.app.bodyHeight - 300
        }
      })
    },
    created () {
    },
    methods: {
      /*类型改变*/
      typeChange (value) {
        this.searchParams.type = value
        this.onRefresh('rest')
      },
      /*状态改变*/
      statusChange (value) {
        this.searchParams.status = value
        this.onRefresh('rest')
      },
      clickItem (item) {
        this.$emit('selectItem', this.systemModule, item.id, item.name, item.plotType)
      },
      /*分页改变*/
      onPageChange (page, pageSize) {
        this.searchParams.number = page - 1
        this.searchParams.size = pageSize
        this.onRefresh()
      },
      /*查询方法*/
      onSearch () {
        this.onRefresh('rest')
      },
      onRefresh (restPage) {
        if (restPage) {
          this.searchParams.number = 0
        }
        this.$http(this, {
          url: SERVICE_URLS.equipment2.facilityApi.mapSearch,
          data: this.searchParams,
          noTips: true,
          loading: 'spinShow',
          success: (data) => {
            this.searchData = data.body.content
            this.pagination.total = data.body.totalElements
            this.pagination.current = this.searchParams.number + 1
          },
          error: () => {
          }
        })
      }
    }
  }
</script>