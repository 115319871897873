const text = () => {
  return {
    draggable: false,
    topWhenClick: true,
    cursor: 'pointer',
    text: null,
    fontSize: 14,
    color: '#111213',
    position: null,
    backgroundColor: 'transparent',
    border: '1px solid #e1e1e1',
    // 备注信息显示级别
    minZoom: 11,
    maxZoom: 18,
    zooms: [11, 18]
  }
}

export default text