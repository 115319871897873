<template>
  <a-spin :spinning="spinShow">
    <div v-malihu-scrollbar style="overflow-y: auto;" :style="{'max-height': bodyHeight + 'px'}">
      <a-form :form="form" class="form-container-body-form">
        <a-row>
          <a-form-item label="名称" v-bind="formItemLayout">
            <a-input v-show="!chooseData.systemModule"
                     v-decorator="['title',
                                                  {rules:[
                                                    {type:'string',required:true,message:'名称不能为空'}
                                                    ],trigger:'change'}
                                                  ]"
                     placeholder="请输入名称"></a-input>
            <span v-show="chooseData.systemModule">{{form.getFieldValue('title')}}</span>
          </a-form-item>
        </a-row>
        <a-row>
          <a-form-item label="线条粗细(px)" v-bind="formItemLayout">
            <a-input-number style="width: 100%"
                            v-decorator="['strokeWeight',
                                              {rules:[
                                                {required:true,message:'请输入线条粗细(px)'}
                                                ],trigger:'change',initialValue:2}
                                              ]"
                            placeholder="请输入线条粗细(px)"></a-input-number>
          </a-form-item>
        </a-row>
        <a-row>
          <a-form-item label="线条颜色" v-bind="formItemLayout">
            <!--<a-input
              v-decorator="['strokeColor',
                                {rules:[
                                  {type:'string',required:true,message:'请输入线条颜色'}
                                  ],trigger:'change',initialValue:'#1890ff'}
                                ]"
              placeholder="请输入线条颜色"></a-input>-->
            <chrome-color-picker
              v-decorator="['strokeColor',
                                   {rules:[
                                     {type:'string',required:true,message:'请选择线条颜色'}
                                     ],trigger:'change',initialValue:'#2d8cf0'}
                                   ]"
            ></chrome-color-picker>
          </a-form-item>
        </a-row>
        <a-row>
          <a-form-item label="线条透明度" v-bind="formItemLayout">
            <a-input-number style="width: 100%"
                            :min="0"
                            :max="1"
                            :step="0.1"
                            v-decorator="['strokeOpacity',
                                              {rules:[
                                                {required:true,message:'线条透明度(0-1)'}
                                                ],trigger:'change',initialValue:0.9}
                                              ]"
                            placeholder="请输入线条透明度(0-1)"></a-input-number>
          </a-form-item>
        </a-row>
        <a-row>
          <a-form-item label="线条样式" v-bind="formItemLayout" required>
            <a-radio-group v-decorator="['strokeStyle',{initialValue:'solid'}]">
              <a-radio :value="'solid'">实线</a-radio>
              <a-radio :value="'dashed'">虚线</a-radio>
            </a-radio-group>
          </a-form-item>
        </a-row>
        <a-row v-if="form.getFieldValue('strokeStyle') === 'dashed'" key="strokeDasharray">
          <a-form-item label="虚线间隙样式" v-bind="formItemLayout">
            <a-input
              v-decorator="['strokeDasharray',
                               {rules:[
                                 {type:'string',required:true,message:'请输入虚线间隙样式'}
                                 ],trigger:'change',initialValue:'[10, 10]'}
                               ]"
              placeholder="请输入虚线间隙样式"></a-input>
          </a-form-item>
        </a-row>
        <a-row>
          <a-form-item label="填充颜色" v-bind="formItemLayout">
            <!--<a-input
              v-decorator="['fillColor',
                                {rules:[
                                  {type:'string',required:true,message:'请输入填充颜色'}
                                  ],trigger:'change',initialValue:'#FFAA00'}
                                ]"
              placeholder="请输入填充颜色"></a-input>-->
            <chrome-color-picker
              v-decorator="['fillColor',
                                     {rules:[
                                       {type:'string',required:true,message:'请选择填充颜色'}
                                       ],trigger:'change',initialValue:'#FFAA00'}
                                     ]"
            ></chrome-color-picker>
          </a-form-item>
        </a-row>
        <a-row>
          <a-form-item label="填充透明度" v-bind="formItemLayout">
            <a-input-number style="width: 100%"
                            :min="0"
                            :max="1"
                            :step="0.1"
                            v-decorator="['fillOpacity',
                                              {rules:[
                                                {required:true,message:'填充透明度(0-1)'}
                                                ],trigger:'change',initialValue:0.2}
                                              ]"
                            placeholder="请输入填充透明度(0-1)"></a-input-number>
          </a-form-item>
        </a-row>
        <a-row>
          <a-form-item label="经纬度" v-bind="formItemLayout">
            <draggable
              v-model="positionItems"
              :dragid="'drag-container'"
              v-bind="dragOption"
              @change="dragChange($event, positionItems)"
            >
              <div class="position" :key="'postion_'+k" v-for="(k, index) in form.getFieldValue('keys')">
                <a-input class="item-panel" style="width: 260px"
                         v-decorator="[`position[${k}]`,
                                                                {rules:[
                                                                  {type:'string',required:true,message:'请选择经纬度'}
                                                                  ],trigger:'change',initialValue:setPositionValue(index)}
                                                                ]"
                         placeholder="请选择经纬度"></a-input>
                <a-icon
                  class="dynamic-delete-button m-l-12"
                  style="font-size: 18px"
                  type="minus-circle-o"
                  @click="() => removePosition(index,k)"
                />
              </div>
            </draggable>
          </a-form-item>
        </a-row>
      </a-form>
    </div>
    <a-row class="m-b-12 m-r-24 m-t-12">
      <a-button type="primary" class="f-r m-l-12" @click="submitAdd" :loading="ifSub"
                v-if="type === 'add'">添加
      </a-button>
      <a-button type="primary" class="f-r m-l-12" @click="submitEdit" :loading="ifSub"
                v-if="type === 'edit'">更新
      </a-button>
      <a-button type="danger" class="f-r m-l-12" @click="submitDelete" :loading="ifSub"
                v-if="type === 'edit'">删除
      </a-button>
    </a-row>
  </a-spin>
</template>

<script>

  import { polygon } from '../../api/map/domain/polygon'
  import { basicForm } from './mixins/common'
  import Draggable from 'vuedraggable'
  import { mapState } from 'vuex'
  import { ChromeColorPicker } from '../../components/color-picker'

  let id = 0

  export default {
    props: {
      instance: {}
    },
    name: 'PolygonForm',
    mixins: [basicForm],
    components: { Draggable, ChromeColorPicker },
    data () {
      return {
        formItem: polygon(),
        dragOption: {
          group: 'position',
          ghostClass: 'sortable-ghost',
          chosenClass: 'chosenClass'
        },
        positionItems: [],
        formType: 'polygon'
      }
    },
    computed: {
      ...mapState({
        bodyHeight: state => {
          return state.app.bodyHeight - 300
        }
      })
    },
    created () {
      this.form.getFieldDecorator('keys', { initialValue: [], preserve: true })
      this.form.getFieldDecorator('fillOpacity', { initialValue: '', preserve: true })
      this.form.getFieldDecorator('fillColor', { initialValue: '', preserve: true })
    },
    methods: {
      getFormData () {
        this.formItem.title = this.form.getFieldValue('title')
        this.formItem.strokeColor = this.form.getFieldValue('strokeColor')
        this.formItem.strokeOpacity = this.form.getFieldValue('strokeOpacity')
        this.formItem.strokeWeight = this.form.getFieldValue('strokeWeight')
        this.formItem.strokeStyle = this.form.getFieldValue('strokeStyle')
        this.formItem.fillColor = this.form.getFieldValue('fillColor')
        this.formItem.fillOpacity = this.form.getFieldValue('fillOpacity')

        if (this.formItem.strokeStyle === 'dashed') {
          const temp = this.form.getFieldValue('strokeDasharray')
          this.formItem.strokeDasharray = eval(temp)
        }

        this.formItem.path = []
        this.positionItems.forEach(x => {
          this.formItem.path.push(eval(x))
        })

        this.formItem['draggable'] = false

        return this.formItem

      },
      // 拖动
      dragChange ($event, positionItems) {
        this.$emit('onValuesChange', { path: this.parsePath(this.positionItems) })
      },
      parsePath () {
        const path = []
        this.positionItems.forEach(x => {
          path.push(eval(x))
        })
        return path
      },
      // 添加position
      addPosition (value) {
        const { form } = this
        const keys = form.getFieldValue('keys')
        const nextKeys = keys.concat(++id)
        // can use data-binding to set
        // important! notify form to detect changes
        form.setFieldsValue({
          keys: nextKeys
        })
        this.positionItems.push(value)
      },
      setPolygonPosition (value) {
        if (this.positionItems.length === value.length) {
          this.positionItems = value
        } else { // 发生了数据变化
          this.form.setFieldsValue({
            keys: []
          })
          this.positionItems = []
          value.forEach(x => {
            this.addPosition(x)
          })
        }
      },
      removePosition (index, k, type) {
        const { form } = this
        // can use data-binding to get
        const keys = form.getFieldValue('keys')
        // can use data-binding to set
        this.positionItems.splice(index, 1)
        form.setFieldsValue({
          keys: keys.filter(key => key !== k)
        })
        if (!type) {
          this.$emit('onValuesChange', { path: this.parsePath(this.positionItems) })
        }
      },
      setPositionValue (index) {
        return this.positionItems[index]
      },
      setDTOFieldsValue (values) {
        return this.getFormData()
      },
      setFormFieldsValue (values, type) {
        this.formItem = polygon()
        const options = JSON.parse(values.options)
        this.form.resetFields()
        this.positionItems = []
        this.$nextTick(() => {

          // 设置经纬度
          options.path.forEach(x => {
            this.addPosition(JSON.stringify(x))
          })

          this.form.setFieldsValue({
            title: values.name,
            strokeColor: options.strokeColor,
            strokeOpacity: options.strokeOpacity,
            strokeWeight: options.strokeWeight,
            strokeStyle: options.strokeStyle,
            fillColor: options.fillColor,
            fillOpacity: options.fillOpacity,
            showDir: options.showDir
          })

          if (options.strokeStyle === 'dashed') {
            this.$nextTick(() => {
              this.form.setFieldsValue({
                strokeDasharray: JSON.stringify(options.strokeDasharray)
              })
            })
          }

          if (!type) {
            this.$emit('formFieldsSetSuccess')
          }
        })
      }
    }
  }
</script>